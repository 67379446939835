<template>
  <div class="container-fluid main-color">
    <!-- start page title -->
    <div class="row">
      <div class="col-12">
        <div class="page-title-box">
          <h4 class="page-title">
            {{
              currentRouteName == "trainer-presentation-assessment-update"
                ? "Edit Record"
                : "Add Record"
            }}
          </h4>
        </div>
      </div>
    </div>

    <!-- end page title -->
    <div class="row">
      <div class="col-lg-12">
        
        <place-holder v-if="loading"></place-holder>
        <div class="card">
          <div v-if="!loading" class="card-body">
            <form class="parsley-examples">
              <div class="row">
                <div class="col-md-7">
                  <div class="row mb-3">
                    <label for="name" class="col-md-3 form-label">
                      Program Name
                      <span class="text-danger">*</span>
                    </label>
                    <div
                      class="col-md-8"
                      v-if="
                        hasPermissions([
                          'trainer-presentation-assessment-create',
                          'trainer-presentation-assessment-update',
                        ])
                      "
                    >
                      <v-select
                        @option:selected="getSelectedProgram"
                        v-model="selectedProgram"
                        label="name"
                        :disabled="
                          currentRouteName ==
                          'trainer-presentation-assessment-update'
                        "
                        :options="programs"
                        :selectable="(options) => isSelectableProgram(options)"
                        :clearable="false"
                        :class="{
                          'p-invalid':
                            v$.selectedProgram.$error || errorFor('name'),
                        }"
                      >
                      </v-select>
                      <v-errors
                        :serverErrors="errorFor('name')"
                        :vuelidateErrors="{
                          errors: v$.selectedProgram.$errors,
                          value: 'Program Name',
                        }"
                      ></v-errors>
                    </div>

                    <div
                      class="col-md-8"
                      v-else-if="
                        hasPermissions(['trainer-presentation-assessment-list'])
                      "
                    >
                      <v-select
                        v-model="selectedProgram"
                        label="name"
                        disabled
                        :clearable="false"
                      >
                      </v-select>
                    </div>
                  </div>

                  <div class="row mb-3">
                    <label for="short_name" class="col-md-3 form-label">
                      Batch
                      <span class="text-danger">*</span>
                    </label>
                    <div
                      class="col-md-8"
                      v-if="
                        hasPermissions([
                          'trainer-presentation-assessment-create',
                          'trainer-presentation-assessment-update',
                        ])
                      "
                    >
                      <v-select
                        @option:selected="getSelectedBatch"
                        v-model="selectedBatch"
                        label="batch_no"
                        :disabled="
                          currentRouteName ==
                          'trainer-presentation-assessment-update'
                        "
                        :options="batch"
                        :selectable="(options) => isSelectableBatch(options)"
                        :clearable="false"
                        :class="{
                          'p-invalid':
                            v$.selectedBatch.$error || errorFor('name'),
                        }"
                      >
                      </v-select>
                      <v-errors
                        :serverErrors="errorFor('name')"
                        :vuelidateErrors="{
                          errors: v$.selectedBatch.$errors,
                          value: 'Batch',
                        }"
                      ></v-errors>
                    </div>

                    <div
                      class="col-md-8"
                      v-else-if="
                        hasPermissions(['trainer-presentation-assessment-create'])
                      "
                    >
                      <v-select
                        v-model="selectedBatch"
                        label="batch_no"
                        disabled
                        :clearable="false"
                      >
                      </v-select>
                    </div>
                  </div>

                  <div class="row mb-3">
                    <label for="short_name" class="col-md-3 form-label">
                      Type
                      <span class="text-danger">*</span>
                    </label>
                    <div
                      class="col-md-8"
                      v-if="
                        hasPermissions([
                          'trainer-presentation-assessment-create',
                          'trainer-presentation-assessment-update',
                        ])
                      "
                    >
                      <v-select
                        @option:selected="
                            selectedLevel = null;
                            selectedEmployee = null;
                            presentation.employee_id = '';
                            presentation.employee_name = '';
                            presentation.join_date ='';
                            weights = [];
                            selectedWeight = '';
                            criterias = [];
                            totalScore = 0;
                            "

                        v-model="presentation.type"
                        :disabled="
                          currentRouteName ==
                          'trainer-presentation-assessment-update'
                        "
                        :options="['In-Class', 'OJT']"
                        :selectable="(options) => isSelectableBatch(options)"
                        :clearable="false"
                        :class="{
                          'p-invalid':
                            v$.selectedBatch.$error || errorFor('name'),
                        }"
                      >
                      </v-select>
                      <v-errors
                        :serverErrors="errorFor('type')"
                        :vuelidateErrors="{
                          errors: v$.presentation.type.$errors,
                          value: 'Type',
                        }"
                      ></v-errors>
                    </div>

                    <div
                      class="col-md-8"
                      v-else-if="
                        hasPermissions(['trainer-presentation-assessment-create'])
                      "
                    >
                      <v-select
                        v-model="presentation.type"
                        disabled
                        :clearable="false"
                      >
                      </v-select>
                    </div>
                  </div>

                  <div class="row mb-3">
                    <label for="short_name" class="col-md-3 form-label">
                      Level
                      <span class="text-danger">*</span>
                    </label>
                    <div
                      class="col-md-8"
                      v-if="
                        hasPermissions([
                          'trainer-presentation-assessment-create',
                          'trainer-presentation-assessment-update',
                        ])
                      "
                    >
                      <v-select
                        @option:selected="getSelectedLevel"
                        v-model="selectedLevel"
                        label="name"
                        :disabled="
                          currentRouteName ==
                          'trainer-presentation-assessment-update'
                        "
                        :options="levels"
                        :selectable="(options) => isSelectableLevel(options)"
                        :clearable="false"
                        :class="{
                          'p-invalid':
                            v$.presentation.level.$error || errorFor('level'),
                        }"
                      >
                      </v-select>
                      <v-errors
                        :serverErrors="errorFor('level')"
                        :vuelidateErrors="{
                          errors: v$.presentation.level.$errors,
                          value: 'Level',
                        }"
                      ></v-errors>
                    </div>

                    <div
                      class="col-md-8"
                      v-else-if="
                        hasPermissions(['trainer-presentation-assessment-list'])
                      "
                    >
                      <v-select
                        v-model="selectedLevel"
                        label="name"
                        disabled
                        :clearable="false"
                      >
                      </v-select>
                    </div>
                  </div>

                  <div class="row mb-3">
                    <label for="short_name" class="col-md-3 form-label">
                      Employee name
                      <span class="text-danger">*</span>
                    </label>
                    <div
                      class="col-md-8"
                      v-if="
                        hasPermissions([
                          'trainer-presentation-assessment-create',
                          'trainer-presentation-assessment-update',
                        ])
                      "
                    >
                      <v-select
                        @option:selected="getSelectedEmployee"
                        v-model="selectedEmployee"
                        label="employee_name"
                        :disabled="
                          currentRouteName ==
                          'trainer-presentation-assessment-update'
                        "
                        :options="employeeLists"
                        :selectable="(option) => isSelectable(option)"
                        :clearable="false"
                        :class="{
                          'p-invalid':
                            v$.selectedEmployee.$error || errorFor('name'),
                        }"
                      >
                      </v-select>
                      <v-errors
                        :serverErrors="errorFor('name')"
                        :vuelidateErrors="{
                          errors: v$.selectedEmployee.$errors,
                          value: 'Employee Name',
                        }"
                      ></v-errors>
                    </div>

                    <div
                      class="col-md-8"
                      v-else-if="
                        hasPermissions(['trainer-presentation-assessment-list'])
                      "
                    >
                      <v-select
                        v-model="selectedEmployee"
                        label="employee_name"
                        :clearable="false"
                        disabled
                      >
                      </v-select>
                    </div>
                  </div>
                </div>

                <div class="row col-md-5">
                  <div class="row mb-3">
                    <label for="short_name" class="col-md-4 form-label">
                      Employee ID
                      <span class="text-danger">*</span>
                    </label>
                    <div class="col-md-8">
                      <input
                        type="text"
                        class="form-control"
                        v-model="presentation.employee_id"
                        disabled
                      />
                    </div>
                  </div>
                  <div class="row mb-3">
                    <label for="date" class="col-md-4 form-label">
                      Joined Date
                      <span class="text-danger">*</span>
                    </label>
                    <div class="col-md-8">
                      <input
                        type="text"
                        class="form-control"
                        v-model="presentation.join_date"
                        disabled
                      />
                    </div>
                  </div>

                  <div class="row mb-3">
                    <label
                      for="evaluating_criteria"
                      class="col-md-4 form-label"
                    >
                      Choose Weight
                      <span class="text-danger">*</span>
                    </label>
                    <div
                      class="col-md-8"
                      v-if="
                        hasPermissions([
                          'trainer-presentation-assessment-create',
                          'trainer-presentation-assessment-update',
                        ])
                      "
                    >
                      <v-select
                        @option:selected="getSelectedCriteria($event)"
                        label="name"
                        :options="weights"
                        :reduce="(weight) => weight.id"
                        :clearable="false"
                        v-model="selectedWeight"
                        :selectable="(options) => isSelectableCriteria(options)"
                        :class="{
                          'p-invalid':
                            v$.selectedWeight.$error ||
                            errorFor('Criteria Weight'),
                        }"
                      >
                      </v-select>
                      <v-errors
                        :serverErrors="errorFor('Criteria Weight')"
                        :vuelidateErrors="{
                          errors: v$.selectedWeight.$errors,
                          value: 'Criteria Weight',
                        }"
                      ></v-errors>
                    </div>

                    <div
                      class="col-md-8"
                      v-else-if="
                        hasPermissions(['trainer-presentation-assessment-list'])
                      "
                    >
                      <v-select
                        label="evaluating_criteria_name"
                        :clearable="false"
                        v-model="selectedWeight"
                        disabled
                      >
                      </v-select>
                    </div>
                  </div>
                </div>
              </div>

              <div
                class="progress mt-3"
                style="height: 0.5px; background-color: #ccc !important"
              >
                <div class="progress-bar" role="progressbar"></div>
              </div>

              <div class="row g-3 mt-3">
                <div class="col-sm-4">
                  <h4 class="mb-3">Criteria</h4>
                </div>
                <div class="col-sm-4">
                  <h4 class="mb-3">Scores:*</h4>
                </div>
                <div class="col-sm-4">
                  <h4 class="mb-3">Remark:</h4>
                </div>
              </div>

              <template
                v-if="
                  hasPermissions([
                    'trainer-presentation-assessment-create',
                    'trainer-presentation-assessment-update',
                  ])
                "
                >
                <div
                  class="row g-3"
                  v-for="(criteria, index) in criterias"
                  :key="index"
                >
                  <div class="col-sm-4">
                    <p class="mb-3 pt-2 pl-2">{{ criteria.name }}</p>
                  </div>
                  <div class="col-sm-4">
                    <div class="mt-2">
                      <v-select
                        v-model="criteria.selectedScore"
                        :options="scoreOptions(criteria)"
                        :clearable="false"
                        @option:selected="updateTotalScore"
                      ></v-select>
                    </div>
                  </div>
                  <div class="col-sm-4">
                    <div class="mt-2">
                      <input
                        type="text"
                        class="form-control mt-2"
                        placeholder="Remark"
                        aria-label="Remark"
                        v-model="criteria.remark"
                      />
                    </div>
                  </div>
                </div>
              </template>

              <template
                v-else-if="hasPermissions(['trainer-presentation-assessment-update'])"
              >
                <div
                  class="row g-3"
                  v-for="(criteria, index) in criterias"
                  :key="index"
                >
                  <div class="col-sm-4">
                    <p class="mb-3 pt-2 pl-2">{{ criteria.name }}</p>
                  </div>
                  <div class="col-sm-4">
                    <div class="mt-2">
                      <v-select
                        v-model="criteria.selectedScore"
                        :clearable="false"
                        disabled
                      ></v-select>
                    </div>
                  </div>
                  <div class="col-sm-4">
                    <div class="mt-2">
                      <input
                        type="text"
                        class="form-control mt-2"
                        placeholder="Remark"
                        aria-label="Remark"
                        v-model="criteria.remark"
                        disabled
                      />
                    </div>
                  </div>
                </div>
              </template>

              <div class="row g-3 pt-2">
                <div class="col-sm-4">Total Score</div>
                <div class="col-sm-4">
                  <input
                    type="text"
                    class="form-control"
                    v-model="totalScore"
                    disabled
                  />
                </div>
              </div>

              <div
                class="row"
                v-if="
                  hasPermissions([
                    'trainer-presentation-assessment-create',
                    'trainer-presentation-assessment-update',
                  ])
                "
              >
                <div class="col-md-12">
                  <div class="text-center mt-3 mb-3">
                    <router-link :to="{ name: 'trainer-presentation-assessment' }">
                      <button type="button" class="btn w-sm btn-secondary me-5">
                        Cancel
                      </button>
                    </router-link>
                    <button
                      v-if="totalScore == 0"
                      style="cursor: not-allowed"
                      data-bs-toggle="tooltip"
                      title="Your total score is still 0."
                      type="button"
                      class="btn w-sm btn-success waves-effect waves-light px-3"
                    >
                      <span>Save</span>
                    </button>
                    <button
                      v-else
                      :disabled="isLoading ? true : false"
                      type="button"
                      class="btn w-sm btn-success waves-effect waves-light px-3"
                      @click="
                        currentRouteName === 'trainer-presentation-assessment-update'
                          ? updateTrainerPresentationAssessment()
                          : createTrainerPresentationAssessment()
                      "
                    >
                      <span
                        v-if="isLoading"
                        class="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      {{ isLoading == true ? "Loading..." : "Save" }}
                    </button>
                  </div>
                </div>
                <!-- end col -->
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { useToast } from "vue-toastification";
import useVuelidate from "@vuelidate/core";
import { required, requiredIf } from "@vuelidate/validators";
import validationErrors from "../../../mixins/validationErrors";
import userHasPermissions from "../../../mixins/userHasPermissions";
import { levels } from '../../../const';

export default {
  setup() {
    const toast = useToast();
    return { v$: useVuelidate(), toast };
  },
  components: {
    // eslint-disable-next-line vue/no-unused-components
    vSelect,
  },
  mixins: [validationErrors, userHasPermissions],
  data() {
    return {
      programs: this.$store.getters["odoo/getAllPrograms"],
      batch: [],
      levels: levels,
      employeeLists: [],
      criterias: [],
      totalScore: 0,
      existedEmployees: [],
      presentation: {
        type: "",
        program_id: "",
        program_name: "",
        program_short_name: "",
        program_batch_id: "",
        program_batch_no: "",
        join_date: "",
        available: "",
        level: "",
      },
      weights: [],
      selectedProgram: null,
      selectedBatch: null,
      selectedEmployee: null,
      selectedWeight: null,
      selectedLevel: null,
      loading: false,
      isLoading: false,
      baseUrl: process.env.VUE_APP_BASE_URL,
       
    };
  },
  validations() {
    return {
      selectedProgram: { required },
      selectedBatch: { required },
      selectedEmployee: { required },
      selectedLevel: { required },
      selectedWeight: { required },
      presentation: {
        type: { required },
        program_id: { required },
        program_name: { required },
        program_short_name: { required },
        program_batch_id: { required },
        program_batch_no: { required },
        join_date: { required },
        available: { required },
        level: {
          required: requiredIf(() => {
            return this.presentation.type != "Orientation";
          }),
        },
      },
    };
  },
  methods: {
    isSelectableCriteria(options) {
      if (!this.selectedWeight || !options) return true;
      if (Array.isArray(this.selectedWeight)) {
        return this.selectedWeight[0].id != options.id;
      } else {
        return this.selectedWeight.id != options.id;
      }
    },
    isSelectableProgram(options) {
      if (!this.selectedProgram || !options) return true;
      return this.selectedProgram.id != options.id;
    },
    isSelectableBatch(options) {
      if (!this.selectedBatch || !options) return true;
      return this.selectedBatch.id != options.id;
    },
    isSelectableLevel(options) {
      if (!this.selectedLevel || !options) return true;
      return this.selectedLevel.id != options.id;
    },
    isSelectable(options) {
      if (this.selectedEmployee) {
        const empExist = this.existedEmployees.some(
          (item) =>
            item.employee_id == this.selectedEmployee.employee_id &&
            item.level == this.selectedLevel.name
        );
        // If the batch doesn't exist, add it to existedEmployees
        if (!empExist) {
          this.existedEmployees = this.existedEmployees.filter(
            (item) => !item.is_selected
          );
          this.existedEmployees.push({
            employee_id: this.selectedEmployee.employee_id,
            level: this.selectedLevel.name,
            is_selected: true,
          });
        }
      }
      // Now, check if the options.id exists in existedEmployees
      return !this.existedEmployees.find(
        (item) =>
          item.employee_id == options.employee_id &&
          item.level == this.selectedLevel.name
      );
    },
    // async getAllLevels() {
    //   await axios.get(`${this.baseUrl}admin/v1/levels`).then((response) => {
    //     this.levels = response.data.data;
    //   });
    // },
    async getAllCriterias() {

      let program_id = this.presentation.program_id;
      let level = this.presentation.level;
      let type = this.presentation.type;

      if (!program_id || !level || !type || !this.selectedBatch) return;

      this.loading = true;
      this.$Progress.start();
      await axios
        .get(
          `${this.baseUrl}admin/v2/trainer-assessment-getCriteria?program_id=${program_id}&type=${type}&level=${level}&is_presentation=1`
        )
        .then((response) => {
          this.weights = [];
          let res = response.data.data;
          for(let val of res){
            if(val.trainer_criterias.length) {
              this.weights.push(val);
            }
          }
          // console.log('mmt', res)
          //   if(res.trainer_criterias) {        
          //       this.weights = res;
          //       console.log('You true')
          //   }else{
          //     console.log(res.trainer_criterias)
          //   }
          
          this.loading = false;
          this.$Progress.finish();
        })
        .catch((error) => {
          this.error = error.response;
          this.toast.error("Something went wrong,");
          this.loading = false;
          this.$Progress.finish();
        });
    },
    getSelectedCriteria(val) {
      console.log(val.trainer_criterias);
      this.criterias = [];
      this.totalScore = 0;

      this.criterias = val.trainer_criterias;
      this.selectedWeight = val;
      this.totalScore = val.trainer_criterias.reduce(
        (total, value) => total + value.lowest_score,
        0
      );
      this.criterias.forEach((criteria) => {
        criteria.selectedScore = criteria.lowest_score;
        criteria.remark = null;
      });
    },

    getSelectedProgram() {
      if (this.selectedProgram) {
        this.selectedBatch = null;
        this.criterias = [];
        this.totalScore = 0;
        this.clearData();
        this.presentation.program_id = this.selectedProgram.id;
        this.presentation.program_name = this.selectedProgram.name;
        this.presentation.program_short_name = this.selectedProgram.short_name;
        this.getBatchByProgram(this.presentation.program_id);
      }
    },
    getSelectedBatch() {
      if (this.selectedBatch) {
        this.clearData();
        this.presentation.program_batch_id = this.selectedBatch.id;
        this.presentation.program_batch_no = this.selectedBatch.batch_no;
        this.presentation.available = this.selectedBatch.available;
      }
    },
    getSelectedEmployee() {
      if (this.selectedEmployee) {
        this.presentation.employee_id = this.selectedEmployee.employee_id;
        this.presentation.employee_name = this.selectedEmployee.employee_name;
        this.presentation.join_date = this.selectedBatch.join_date;
      }
    },
    getSelectedLevel() {
      if(!this.selectedBatch) return;
      if (this.selectedLevel) {
        this.selectedWeight = null;
        this.criterias = [];
        this.totalScore = 0;
        this.selectedEmployee = null;
        this.presentation.level = this.selectedLevel.name;
        this.getEmployeeByBatch(this.presentation.program_batch_id);
        this.getAllCriterias();
      }
    },
    clearData() {
      this.selectedEmployee = null;
      this.selectedWeight = null;
      this.selectedLevel = null;
      this.presentation.employee_id = "";
      this.presentation.employee_name = "";
      this.presentation.type =""
      this.presentation.join_date = "";
      this.criterias = [];
      this.totalScore = 0;
    },

    updateTotalScore() {
      let totalScore = 0;
      for (let i = 0; i < this.criterias.length; i++) {
        totalScore += this.criterias[i].selectedScore;
      }
      this.totalScore = totalScore;
    },

    scoreOptions(criteria) {
      const options = [];
      for (let i = criteria.lowest_score; i <= criteria.highest_score; i++) {
        options.push(i);
      }
      return options;
    },

    async getBatchByProgram(program_id) {
    
    this.loading = true;
    this.$Progress.start();
    await axios
      .get(
        `${this.baseUrl}admin/v1/program-batches/list?program_id=${program_id}`
      )
      .then((response) => {
      
        this.batch = response.data.data;
    
        this.loading = false;
        this.$Progress.finish();
      })
      .catch(() => {
        this.toast.error("Not Found Batch!");
      });
  },
  async getEmployeeByBatch(batch_id) {
    this.loading = true;
    this.$Progress.start();
    await axios
      .get(
        `${this.baseUrl}admin/v1/program-batch/employees/list/${batch_id}`
      )
      .then((response) => {
      
        this.employeeLists = response.data.data.employee_ids.map(
          (item) => {
            return {
              employee_name: item.employee.name,
              employee_id: item.employee.emp_id,
            };
          }
        );

        this.loading = false;
        this.$Progress.finish();
      })
      .catch(() => {
        this.toast.error("Not Found Employee!");
      });
  },

    async createTrainerPresentationAssessment() {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) return;
      this.isLoading = true;

      let criteria = this.criterias.map((criteria) => {
        return {
          trainer_criteria_id: criteria.id,
          score: criteria.selectedScore,
          remark: criteria.remark,
        };
      });

      let toSendData = {
            program_id: this.presentation.program_id,
            batch_id: this.presentation.program_batch_id,
            employee_id: this.presentation.employee_id,
            assessor_employee_id: this.$store.state.auth.user.employee_id,
            type: this.presentation.type,
            level : this.presentation.level,
            assessment_point : this.selectedWeight.point,
            assessment_criteria_id: this.selectedWeight.id,
            trainer_criterias : criteria,
            is_presentation : 1
      };

      console.log(criteria);
      await axios
        .post(`${this.baseUrl}admin/v2/trainer-assessment`, toSendData)
        .then(() => {
          this.toast.success("Successfully Presentation Assessment Created.");
          this.$router.push({ name: "trainer-presentation-assessment" });
          this.isLoading = false;
        })
        .catch((error) => {
          this.error = error.response;
          this.toast.error("Something went wrong.");
          this.isLoading = false;
        });

    },
    async getPresentationAssessmentById() {
        this.loading = true;
      // this.$Progress.start();
      await axios
        .get(
          `${this.baseUrl}admin/v2/trainer-assessment/${this.$route.params.id}`
        )
        .then(async (response) => {
          const data = response.data.data;
          this.selectedProgram = data.program_name;
          this.selectedBatch = data.batch_no;
          this.selectedEmployee = data.employee_name;
          // this.hrd_feedback = data.hrd_feedback;
          // this.background = data.background;
          // this.orientation = data;
            this.selectedLevel = data.level
            this.presentation.level = data.level
            this.presentation.program_id = data.program_id,
            this.presentation.program_batch_id = data.batch_id,
            this.presentation.employee_id = data.employee_id,
            this.presentation.join_date = data.join_date
            this.presentation.type = data.type
            // assessment_point : this.selectedWeight.point,
            // assessment_criteria_id: this.selectedWeight.id,
            // trainer_criterias : criteria
            // this.criterias = data.trainer_criterias
            await this.getAllCriterias();
            this.selectedWeight = data.assessment_criteria_id
            

        //   this.selectedWeight = this.weights.filter(
        //     (weight) =>
        //       weight.id ==
        //       data.trainer_presentation_assessment[0]
        //         .training_orientation_criteria.evaluating_weights.id
        //   );
          // console.log(data.trainer_orientation_assessment[0]
          //       .training_orientation_criteria.evaluating_weights.id, 'sele')
            // 
          data.trainer_criterias.forEach((assessment) => {
            this.criterias.push({
              id: assessment.id,
              name: assessment.criteria_name,
              lowest_score: assessment.lowest_score,
              highest_score: assessment.highest_score,
              selectedScore: assessment.score,
              remark: assessment.remark,
            });
            this.updateTotalScore();

            //       this.loading = false;
            // this.$Progress.finish();
          });
        //   this.getBatchByProgram(data.program_id);
        //   this.getEmployeeByBatch(data.program_batch_id);
        })
        .catch((error) => {
          this.error = error.response;
          this.toast.error("Something went wrong.");
          this.loading = false;
          this.$Progress.finish();
        });
    },
    async updateTrainerPresentationAssessment() {
        this.isLoading = true;
        let trainer_criterias = this.criterias.map((criteria) => {
        return {
            trainer_criteria_id: criteria.id,
            score: criteria.selectedScore,
            remark: criteria.remark,
            };
        });

        let toSendData = this.presentation;
        toSendData.trainer_criterias = trainer_criterias;

        await axios
            .put(
            `${this.baseUrl}admin/v2/trainer-assessment/${this.$route.params.id}`,toSendData)
            .then(() => {
            this.toast.success("Successfully InClass Assessment Updated.");
            this.$router.push({ name: "trainer-presentation-assessment" });
            this.isLoading = false;
            })
            .catch((error) => {
            this.error = error.response;
            this.toast.error("Something went wrong.");
            this.isLoading = false;
            });
    },
    async getExistedEmployees() {
      // this.loading = true;
      // this.$Progress.start();
      await axios
        .get(
          `${this.baseUrl}admin/v2/trainer-inclass-assessment-getExistedEmployee/${this.$store.state.auth.user.employee_id}`
        )
        .then((response) => {
        
          this.existedEmployees = response.data.data;
          this.loading = false;
          this.$Progress.finish();
        })
        .catch((error) => {
          this.error = error.response;
          this.toast.error("Something went wrong");
          // this.loading = false;
          // this.$Progress.finish();
        });
    },
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
  },

  async created() {
    if (this.currentRouteName == "trainer-presentation-assessment-update") {
      await this.getPresentationAssessmentById();
    }
  },
};
</script>

<style>
.custom-selectCSS {
  --vs-dropdown-min-width: 160px;
  --vs-dropdown-max-height: 200px;
}
.custom-v-select .vs__dropdown-toggle .vs__open-indicator::before {
  display: none;
}
.selectScore {
  padding: 0.45rem 0.9rem;
}
.scoreBox {
  width: 262px;
  margin-left: 12px;
}
</style>
